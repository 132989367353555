import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import { Spacer } from 'components';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

import {
  Header,
  useImpressionData,
  Services,
  Sustainability,
  Facts
} from 'sections/impressionPage';

import { useKisImpressionenPageData } from 'graphqlHooks';

const Impression = () => {
  const { seo, header, services } = useImpressionData();

  const {
    facts: cmsFact,
    header: cmsHeader,
    services: cmsServices,
    sustainability: cmsSustainability
  } = useKisImpressionenPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Spacer
        height={['3.8rem', '7.8rem', '10.5rem', '0.3rem', '9.2rem', '9rem']}
      />
      <Header staticData={header} data={cmsHeader} />
      <Spacer height={['24rem', '40rem', '40rem', '16rem', '24rem', '40rem']} />
      <Services staticData={services} data={cmsServices} />
      <Spacer
        height={['16rem', '24rem', '23.1rem', '16rem', '24rem', '40rem']}
      />
      <Sustainability data={cmsSustainability} />
      <Spacer height={['16rem', '16rem', '24rem', '8rem', '24rem', '24rem']} />
      <Facts data={cmsFact} />
      <Spacer height={['16rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
    </>
  );
};

export default injectIntl(Impression);
