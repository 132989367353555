import React from 'react';
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';
import { ImageContainer } from 'components';
import { gsap } from 'gsap';
import { convertApiHtmlText } from 'graphqlHooks/helper';

const Header = ({
  staticData: { images },
  data: { caption, description, headline }
}) => {
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const headingRef = React.useRef();

  React.useLayoutEffect(() => {
    gsap.set([ref1.current, ref2.current, ref3.current], {
      y: '120%'
    });
    gsap.set(headingRef.current, {
      y: 50
    });
  }, []);

  React.useEffect(() => {
    const timeline = gsap
      .timeline({ delay: 0.1 })
      .to(headingRef.current, {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'power1'
      })
      .to(
        [ref1.current, ref2.current, ref3.current],
        {
          y: 0,
          duration: 2,
          autoAlpha: 1,
          delay: 0.3,
          ease: 'power4',
          stagger: 0.1
        },
        '<'
      );

    const killAnimation = () => {
      timeline.pause(0).kill(true);
    };

    return killAnimation;
  }, []);

  return (
    <Section id="header">
      <Headline headingRef={headingRef} headline={headline} />
      <ImageContainer
        designatedRef={ref1}
        src={images[0].src}
        imageAlt={images[0].alt}
        sx={{
          gridRow: [1],
          gridColumn: ['1/6', '1/5', '1/7', '1/5', '1/6', '1/5'],
          alignSelf: ['start', 'start', 'start', 'end', 'end', 'start'],
          boxShadow: 'elevation100',
          visibility: 'hidden'
        }}
      />
      <ImageContainer
        designatedRef={ref2}
        src={images[1].src}
        imageAlt={images[1].alt}
        sx={{
          gridRow: [3, 3, 3, 2, 2, 2],
          gridColumn: ['5/13', '8/13', '17/25', '17/25', '18/25', '19/25'],
          mb: ['3.8rem', '14.9rem', '13.1rem', '10.2rem', '18.5rem', '20.7rem'],
          boxShadow: 'elevation100',
          visibility: 'hidden'
        }}
      />
      <ImageContainer
        designatedRef={ref3}
        src={images[2].src}
        imageAlt={images[2].alt}
        sx={{
          gridRow: [4, 3, 3, 2, 2, 2],
          gridColumn: ['2/11', '1/7', '2/12', '1/10', '1/9', '2/9'],
          alignSelf: 'end',
          boxShadow: 'elevation1000',
          visibility: 'hidden'
        }}
      />
      <Caption caption={caption} />
      <TextBlock paragraph={description} />
    </Section>
  );
};

export { Header };

const Headline = ({ headline, headingRef }) => (
  <Heading
    ref={headingRef}
    as="h1"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      textAlign: 'center',
      gridRow: [2, 2, 2, 1, 1, 1],
      gridColumn: ['1/13', '1/13', '3/23', '5/21', '6/20', '7/19'],
      mt: ['2.4rem', '4.4rem', '8.9rem', '3.5rem', '11.8rem', '7.3rem'],
      mb: ['10rem', '14rem', '13.7rem', '3.2rem', '5.6rem', '3.6rem'],
      visibility: 'hidden',
      fontFamily: 'primary.normal',
      lineHeight: 1.5,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['2.8rem', '3.8rem', '4rem', '2.8rem', '3.6rem', '4.2rem']
    }}
  />
);

const TextBlock = ({ paragraph }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(paragraph.html)
    }}
    sx={{
      gridRow: [6, 5, 5, 4, 4, 4],
      gridColumn: ['1/13', '3/13', '6/25', '7/24', '12/25', '12/25'],
      fontFamily: 'primary.normal',
      lineHeight: 1.6,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['2rem', '2.4rem', '2.8rem', '2rem', '2.6rem', '3rem']
    }}
  />
);

const Caption = ({ caption }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(caption.html)
    }}
    sx={{
      gridRow: [5, 4, 4, 3, 3, 3],
      gridColumn: ['2/11', '1/7', '2/11', '1/9', '1/9', '2/9'],
      mt: ['2.4rem', '3.2rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem'],
      mb: ['21.5rem', '24.6rem', '17.9rem', '10.5rem', 0, '10.5rem'],
      fontFamily: 'body.normal',
      lineHeight: 1.75,
      '& > span': {
        fontFamily: 'body.bold'
      },
      fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
    }}
  />
);
