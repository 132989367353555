import { useStaticQuery, graphql } from 'gatsby';

export const useImpressionData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: impressionDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          images {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        services {
          images {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  return data;
};
