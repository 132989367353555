import React from 'react';
import { ImageContainer, BorderedImage } from 'components';
import {
  Section,
  Heading,
  Paragraph,
  Box,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Services = ({
  staticData: { images },
  data: { caption, description, headline }
}) => {
  return (
    <Section id="services">
      <Headline headline={headline} />
      <TextBlock
        paragraph={description}
        sx={{
          gridRow: 2,
          gridColumn: ['1/13', '1/12', '1/21', '1/17', '1/13', '2/16'],
          mb: ['9.6rem', '12.8rem', '11.2rem', '3.2rem', 0, 0]
        }}
      />
      <TextBlock
        paragraph={caption}
        sx={{
          gridRow: [5, 5, 5, 5, 6, 6],
          gridColumn: ['2/11', '3/13', '2/19', '3/17', '12/23', '11/22'],
          mt: ['17.9rem', '34.6rem', '31.2rem', '12.7rem', 0, 0],
          mb: ['4rem', '8rem', '8rem', '4rem', '11.8rem', '11.3rem']
        }}
      />
      <Spacer />
      <BorderedImage
        yOffset={-11}
        xOffset={11}
        image={images[0].src}
        imageAlt={images[0].alt}
        imageSx={{ boxShadow: 'elevation1000' }}
        sx={{
          gridRow: [3],
          gridColumn: ['3/12', '4/12', '12/24', '15/24', '16/24', '17/24'],
          mb: ['3.2rem', '5.4rem', 0, 0, 0, '7rem'],
          alignSelf: 'start'
        }}
      />
      <ImageContainer
        src={images[1].src}
        imageAlt={images[1].alt}
        sx={{
          gridRow: [4, 4, 3, 3, 3, 3],
          gridColumn: ['1/7', '1/6', '1/11', '6/14', '8/15', '10/16'],
          ml: [fullWidthMinusMargins, fullWidthMinusMargins, 0, 0, 0, 0],
          mt: [0, 0, '19.8rem', '12.7rem', '21.4rem', '24.5rem'],
          mb: [0, 0, '16.2rem', '13.4rem', '12.9rem', '12.7rem'],
          boxShadow: 'elevation700'
        }}
      />
      <ImageContainer
        src={images[2].src}
        imageAlt={images[2].alt}
        sx={{
          gridRow: [4, 4, 3, 3, 3, 3],
          gridColumn: ['8/13', '7/13', '12/21', '15/22', '16/22', '17/22'],
          mr: [fullWidthMinusMargins, 0, 0, 0, 0, 0],
          alignSelf: ['stretch', 'stretch', 'end', 'end', 'end', 'end'],
          boxShadow: 'elevation100'
        }}
      />
      <BorderedImage
        yOffset={11}
        xOffset={-11}
        image={images[3].src}
        imageAlt={images[3].alt}
        imageSx={{ boxShadow: 'elevation1000' }}
        sx={{
          gridRow: [6],
          gridColumn: ['2/11', '2/10', '2/14', '3/12', '2/10', '2/9'],
          mb: ['6.4rem', '10rem', '7.4rem', '6.4rem', '9.2rem', '12.4rem']
        }}
      />
      <ImageContainer
        src={images[4].src}
        imageAlt={images[4].alt}
        sx={{
          gridRow: [7],
          gridColumn: ['1/6', '1/7', '5/14', '5/12', '4/10', '4/9'],
          ml: [fullWidthMinusMargins, 0, 0, 0, 0, 0],
          boxShadow: 'elevation100'
        }}
      />
      <ImageContainer
        src={images[5].src}
        imageAlt={images[5].alt}
        sx={{
          gridRow: [7],
          gridColumn: ['7/13', '8/13', '15/25', '13/21', '11/18', '10/16'],
          mr: [fullWidthMinusMargins, fullWidthMinusMargins, 0, 0, 0, 0],
          transform: [
            null,
            null,
            'translateY(-12.9rem)',
            'translateY(-13.2rem)',
            'translateY(-13.4rem)',
            'translateY(-12.5rem)'
          ],
          boxShadow: 'elevation700'
        }}
      />
    </Section>
  );
};

const Headline = ({ headline }) => (
  <Heading
    as="h2"
    // type="h2-701"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      gridRow: 1,
      gridColumn: ['1/10', '1/10', '1/19', '1/16', '1/15', '2/15'],
      mb: ['2.4rem', '2.4rem', '3.2rem', '2.4rem', '3.2rem', '3.2rem'],
      fontFamily: 'primary.normal',
      lineHeight: 1.2,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['3.8rem', '4.2rem', '5.6rem', '3.4rem', '4.6rem', '5.4rem']
    }}
  />
);

const TextBlock = ({ paragraph, sx, ...props }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(paragraph.html)
    }}
    sx={{
      lineHeight: 1.6,
      fontFamily: 'primary.normal',
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['2rem', '2.4rem', '2.8rem', '2rem', '2.6rem', '3rem'],
      ...sx
    }}
    {...props}
  />
);

const Spacer = () => (
  <Box
    sx={{
      gridRow: 5,
      display: ['none', 'none', 'none', 'none', 'block', 'block'],
      height: [0, 0, 0, 0, '24rem', '20.7rem']
    }}
  />
);
