import React from 'react';
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';
import { ListItem } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

const styles = [
  {
    gridColumn: [
      '1/span 12',
      '2/span 9',
      '2/span 14',
      '1/span 11',
      '2/span 10',
      '4/span 8'
    ],
    gridRow: 3
  },
  {
    gridColumn: [
      '1/span 12',
      '2/span 9',
      '10/span 14',
      '14/span 11',
      '14/span 10',
      '14/span 8'
    ],
    gridRow: [4, 4, 4, 3, 3, 3]
  },
  {
    gridColumn: [
      '1/span 12',
      '2/span 9',
      '2/span 14',
      '1/span 11',
      '2/span 10',
      '4/span 8'
    ],
    gridRow: [5, 5, 5, 4, 4, 4]
  },
  {
    gridColumn: [
      '1/span 12',
      '2/span 9',
      '10/span 14',
      '14/span 11',
      '14/span 10',
      '14/span 8'
    ],
    gridRow: [6, 6, 6, 4, 4, 4]
  }
];

export const Facts = ({ data: { caption, headline, list, number } }) => {
  return (
    <Section id="facts">
      <Number number={number} />
      <Headline headline={headline} />
      <Caption caption={caption} />
      {list.map((item, index) => (
        <ListItem
          index={index}
          key={item.title}
          headline={item.title}
          paragraph={item.description}
          sx={styles[index]}
        />
      ))}
    </Section>
  );
};

const Number = ({ number }) => (
  <Paragraph
    as="p"
    type="facts-number"
    dangerouslySetInnerHTML={{
      __html: number
    }}
    sx={{
      gridRow: 1,
      gridColumn: [
        '1/span 11',
        '1/span 12',
        '1/span 21',
        '1/span 14',
        '1/span 18',
        '3/span 16'
      ],
      color: 'secondary',
      mixBlendMode: 'multiply',
      fontFamily: 'primary.bold',
      lineHeight: 1,
      fontSize: ['10.2rem', '18.2rem', '21.6rem', '14rem', '26.3rem', '30rem']
    }}
  />
);

const Headline = ({ headline }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      gridRow: 1,
      gridColumn: ['1/13', '2/12', '8/25', '9/24', '11/22', '13/23'],
      mt: ['15%', '17%', '18%', '3%', '4%', '4%'],
      fontFamily: 'primary.normal',
      lineHeight: 1.25,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['2.939rem', '3.8rem', '4.4rem', '2.8rem', '5rem', '4.8rem']
    }}
  />
);

const Caption = ({ caption }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(caption.html)
    }}
    sx={{
      gridRow: 2,
      gridColumn: ['1/11', '2/12', '8/23', '9/23', '11/21', '13/21'],
      mt: ['0.8rem', '1.6rem', '1.6rem', '1.9rem', 0, 0],
      mb: ['15rem', '9rem', '16.0rem', '9.6rem', '16.2rem', '18.8rem'],
      fontFamily: 'body.normal',
      lineHeight: 1.75,
      fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
    }}
  />
);
