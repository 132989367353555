import React from 'react';

import { Section, GridItem, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local components
import { SmallListItem } from 'components';

// Assets
import Pattern from 'assets/patterns/pattern-bg.png';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Sustainability = ({
  data: { caption, description, headline, list }
}) => {
  return (
    <Section
      id="green-list"
      sx={{
        background: ({ colors }) => `url(${Pattern}),${colors.primary}`,
        backgroundSize: 'cover'
      }}>
      <Headline
        headline={headline}
        gridRow={[1]}
        gridColumn={['1/13', '1/13', '3/23', '1/17', '2/16', '3/16']}
        mt={['16rem', '9.9rem', '9.5rem', '8.8rem', '4.2rem', '10.1rem']}
        mb={['7.1rem', '12.0rem', '11.8rem', '4.8rem', '12.5rem', '10.1rem']}
      />
      <Headline
        headline={caption}
        gridRow={[2]}
        gridColumn={['1/11', '3/11', '6/19', '13/25', '13/22', '13/21']}
        mb={['2.4rem', '2.4rem', '3.2rem', '2.4rem', '3.2rem', '3.2rem']}
      />
      <TextBlock paragraph={description} />
      <List listItems={list} />
    </Section>
  );
};

const Headline = ({ headline, ...props }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      color: 'background',
      ...props,
      '&>span': {
        color: 'secondary'
      },
      fontFamily: 'primary.normal',
      lineHeight: 1.25,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem']
    }}
  />
);

const TextBlock = ({ paragraph }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(paragraph.html)
    }}
    sx={{
      color: 'background',
      gridRow: 3,
      gridColumn: ['1/13', '3/13', '6/20', '13/24', '13/23', '13/22'],
      mb: ['9.4rem', '20.4rem', '12rem', 0, 0, 0],
      '& > span': {
        color: 'secondary'
      },
      fontFamily: 'body.normal',
      lineHeight: 1.75,
      fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.9rem']
    }}
  />
);

const List = ({ listItems }) => (
  <GridItem
    sx={{
      gridRow: [4, 4, 4, 3, 3, 3],
      gridColumn: ['1/12', '2/11', '3/17', '1/11', '3/12', '4/11'],
      mt: [0, 0, 0, '5.3rem', 0, 0],
      mb: ['5.4rem', '21.6rem', '11.3rem', '10.6rem', '4.1rem', '11.6rem']
    }}>
    {listItems.map((item) => (
      <SmallListItem
        variant="bold"
        color="background"
        key={item.text}
        text={item.text}
        sx={{ mb: '4.8rem' }}
      />
    ))}
  </GridItem>
);
